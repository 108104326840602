import { createWebHistory, createRouter } from "vue-router"

export const routes = {
  "home": {
    path: "/",
    name: "home",
    component: () => import("./components/Welcome.vue"),
  },
  "login": {
    path: "/login",
    name: "login",
    component: () => import("./components/Login.vue"),
  },
  "signup": {
    path: "/signup",
    name: "signup",
    component: () => import("./components/Signup.vue"),
  },
  "profile": {
    path: "/profile",
    name: "profile",
    component: () => import("./components/Profile.vue"),
  },
  "collections": {
    path: "/collections",
    name: "collections",
    component: () => import("./components/Collections.vue"),
  },
  "collection": {
    path: "/collection/:code",
    name: "collection",
    component: () => import("./components/Collection.vue"),
    props: route => ({ code: String(route.params.code) }),
  },
  "ladder-slasher": {
    path: "/ladder-slasher",
    name: "ladder-slasher",
    component: () => import("./components/LadderSlasherList.vue"),
  },
  "ladder-slasher-update": {
    path: "/ladder-slasher/:id",
    name: "ladder-slasher-update",
    component: () => import("./components/LadderSlasherForm.vue"),
    props: route => ({ id: String(route.params.id) }),
  },
  "notifications": {
    path: "/notifications",
    name: "notifications",
    component: () => import("./components/Notifications.vue"),
    props: route => ({ code: String(route.params.code) }),
  },
  "thing-page": {
    path: "/collection/:collection_code/:thing_slug",
    name: "thing-page",
    component: () => import("./components/ThingPage.vue"),
    props: route => ({ collection_code: String(route.params.collection_code), thing_slug: String(route.params.thing_slug) }),
  },
  "admin-home":{
    path: "/admin",
    name: "admin-home",
    component: () => import("./components/admin/AdminHome.vue"),
  },
  "admin-collection-list": {
    path: "/admin/collection/list",
    name: "admin-collection-list",
    component: () => import("./components/admin/CollectionList.vue"),
  },
  "admin-collection-create": {
    path: "/admin/collection/create",
    name: "admin-collection-create",
    component: () => import("./components/admin/CollectionForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-collection-update": {
    path: "/admin/collection/update/:id_collection",
    name: "admin-collection-update",
    component: () => import("./components/admin/Collection.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-collection-export": {
    path: "/admin/collection/export/:id_collection",
    name: "admin-collection-export",
    component: () => import("./components/admin/Exporter.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-export-list": {
    path: "/admin/export/list",
    name: "admin-export-list",
    component: () => import("./components/admin/ExportList.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-thing-list": {
    path: "/admin/thing/list",
    name: "admin-thing-list",
    component: () => import("./components/admin/ThingList.vue"),
    props: route => ({ create: true }),
  },
  "admin-thing-create": {
    path: "/admin/thing/create/:id_collection",
    name: "admin-thing-create",
    component: () => import("./components/admin/ThingForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-thing-update": {
    path: "/admin/thing/update/:id",
    name: "admin-thing-update",
    component: () => import("./components/admin/ThingForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-attribute-create": {
    path: "/admin/attribute/create/:id_collection",
    name: "admin-attribute-create",
    component: () => import("./components/admin/AttributeForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-attribute-update": {
    path: "/admin/attribute/update/:id",
    name: "admin-attribute-update",
    component: () => import("./components/admin/AttributeForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-attribute_group-list": {
    path: "/admin/attribute_group/list/:id_collection",
    name: "admin-attribute_group-list",
    component: () => import("./components/admin/AttributeGroupList.vue"),
    props: route => ({ id_collection: String(route.params.id_collection) }),
  },
  "admin-attribute_group-create": {
    path: "/admin/attribute_group/create/:id_collection",
    name: "admin-attribute_group-create",
    component: () => import("./components/admin/AttributeGroupForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-attribute_group-update": {
    path: "/admin/attribute_group/update/:id",
    name: "admin-attribute_group-update",
    component: () => import("./components/admin/AttributeGroupForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-attribute-select-update": {
    path: "/admin/attribute_select/update/:id",
    name: "admin-attribute-select-update",
    component: () => import("./components/admin/AttributeSelectForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-crawler-list": {
    path: "/admin/crawler/list",
    name: "admin-crawler-list",
    component: () => import("./components/admin/CrawlerList.vue"),
  },
  "admin-crawler-update": {
    path: "/admin/crawler/update/:id",
    name: "admin-crawler-update",
    component: () => import("./components/admin/CrawlerForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-crawler-trigger": {
    path: "/admin/crawler/trigger/:id_crawler",
    name: "admin-crawler-trigger",
    component: () => import("./components/admin/CrawlerTrigger.vue"),
    props: route => ({ id_crawler: String(route.params.id_crawler) }),
  },
  "admin-crawler-run-update": {
    path: "/admin/crawler_run/update/:id",
    name: "admin-crawler-run-update",
    component: () => import("./components/admin/CrawlerRunForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-crawler-create": {
    path: "/admin/crawler/create",
    name: "admin-crawler-create",
    component: () => import("./components/admin/CrawlerForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-scripts": {
    path: "/admin/scripts",
    name: "admin-scripts",
    component: () => import("./components/admin/Scripts.vue"),
  },
  "admin-bulk-action-list": {
    path: "/admin/bulk-action/list",
    name: "admin-bulk-action-list",
    component: () => import("./components/admin/BulkActionList.vue"),
  },
  "admin-bulk-actions-update": {
    path: "/admin/bulk-actions/update/:id",
    name: "admin-bulk-actions-update",
    component: () => import("./components/admin/BulkActionForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-bulk-action-create": {
    path: "/admin/bulk-action/create",
    name: "admin-bulk-action-create",
    component: () => import("./components/admin/BulkActionForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-dynamic-image-list": {
    path: "/admin/dynamic-image/list",
    name: "admin-dynamic-image-list",
    component: () => import("./components/admin/dynamic-image/DynamicImageList.vue"),
  },
  "admin-dynamic-image-create": {
    path: "/admin/dynamic-image/create",
    name: "admin-dynamic-image-create",
    component: () => import("./components/admin/dynamic-image/DynamicImageForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-dynamic-image-update": {
    path: "/admin/dynamic-image/update/:id",
    name: "admin-dynamic-image-update",
    component: () => import("./components/admin/dynamic-image/DynamicImageForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-user-list": {
    path: "/admin/user/list",
    name: "admin-user-list",
    component: () => import("./components/admin/UserList.vue"),
  },
  "admin-user-update": {
    path: "/admin/user/update/:id",
    name: "admin-user-update",
    component: () => import("./components/admin/UserForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-role-list": {
    path: "/admin/role/list",
    name: "admin-role-list",
    component: () => import("./components/admin/rbac/RoleList.vue"),
  },
  "admin-role-create": {
    path: "/admin/role/create",
    name: "admin-role-create",
    component: () => import("./components/admin/rbac/RoleForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-role-update": {
    path: "/admin/role/update/:id",
    name: "admin-role-update",
    component: () => import("./components/admin/rbac/RoleForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-permission-list": {
    path: "/admin/permission/list",
    name: "admin-permission-list",
    component: () => import("./components/admin/rbac/PermissionList.vue"),
  },
  "admin-permission-create": {
    path: "/admin/permission/create",
    name: "admin-permission-create",
    component: () => import("./components/admin/rbac/PermissionForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-permission-update": {
    path: "/admin/permission/update/:id",
    name: "admin-permission-update",
    component: () => import("./components/admin/rbac/PermissionForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-user-role-list": {
    path: "/admin/user-role/list",
    name: "admin-user-role-list",
    component: () => import("./components/admin/rbac/UserRoleList.vue"),
  },
  "admin-role-permission-list": {
    path: "/admin/role-permission/list",
    name: "admin-role-permission-list",
    component: () => import("./components/admin/rbac/RolePermissionList.vue"),
  },
  "admin-mapper-create": {
    path: "/admin/mapper/create",
    name: "admin-mapper-create",
    component: () => import("./components/admin/MapperForm.vue"),
    props: () => ({ create: true }),
  },
  "admin-mapper-update": {
    path: "/admin/mapper/update/:id",
    name: "admin-mapper-update",
    component: () => import("./components/admin/MapperForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-mapper-list": {
    path: "/admin/mapper/list",
    name: "admin-mapper-list",
    component: () => import("./components/admin/MapperList.vue")
  },
  "admin-scheduler-list": {
    path: "/admin/scheduler/list",
    name: "admin-scheduler-list",
    component: () => import("./components/admin/SchedulerList.vue"),
  },
  "admin-scheduler-update": {
    path: "/admin/scheduler/update/:id",
    name: "admin-scheduler-update",
    component: () => import("./components/admin/SchedulerForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-scheduler-create": {
    path: "/admin/scheduler/create",
    name: "admin-scheduler-create",
    component: () => import("./components/admin/SchedulerForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-watcher-list": {
    path: "/admin/watcher/list",
    name: "admin-watcher-list",
    component: () => import("./components/admin/WatcherList.vue"),
  },
  "admin-watcher-create": {
    path: "/admin/watcher/create",
    name: "admin-watcher-create",
    component: () => import("./components/admin/WatcherForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-watcher-update": {
    path: "/admin/watcher/update/:id",
    name: "admin-watcher-update",
    component: () => import("./components/admin/WatcherForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-workflow-list": {
    path: "/admin/workflow/list",
    name: "admin-workflow-list",
    component: () => import("./components/admin/WorkflowList.vue"),
  },
  "admin-workflow-run-update": {
    path: "/admin/workflow-run/update/:id",
    name: "admin-workflow-run-update",
    component: () => import("./components/admin/WorkflowRunForm.vue"),
    props: route => ({ id: String(route.params.id) }),
  },
  "admin-workflow-update": {
    path: "/admin/workflow/update/:id",
    name: "admin-workflow-update",
    component: () => import("./components/admin/WorkflowForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
  "admin-workflow-create": {
    path: "/admin/workflow/create",
    name: "admin-workflow-create",
    component: () => import("./components/admin/WorkflowForm.vue"),
    props: route => ({ create: true }),
  },
  "admin-changeset-list": {
    path: "/admin/changeset/list",
    name: "admin-changeset-list",
    component: () => import("./components/admin/ChangesetList.vue")
  },
  "admin-changeset-update": {
    path: "/admin/changeset/update/:id",
    name: "admin-changeset-update",
    component: () => import("./components/admin/ChangesetForm.vue"),
    props: route => ({ id: String(route.params.id), create: false }),
  },
}

const router = createRouter({
  history: createWebHistory(),
  routes: Object.values(routes),
})

export default router
