import http from '../http-common'

class UserDataService {

  async like(id_thing) {
    const response = await http.post('/user_thing/like', {
      id_thing
    });
    return response.data;
  }

  async wish(id_thing) {
    const response = await http.post('/user_thing/wish', {
      id_thing
    });
    return response.data;
  }

  async sell(id_thing) {
    const response = await http.post('/user_thing/sell', {
      id_thing
    });
    return response.data;
  }

  async myThings() {
    const response = await http.post('/user_thing/my_things');
    return response.data;
  }

  async tag(id_thing, tag) {
    const response = await http.post('/user_thing/tag', {
      id_thing,
      tag
    });
    return response.data;
  }

  async own(id_thing) {
    const response = await http.post('/user_thing/own', {
      id_thing
    });
    return response.data;
  }

  async unownAny(id_thing) {
    const response = await http.post('/user_thing/unown-any', {
      id_thing
    });
    return response.data;
  }

  async untag(id_thing, tag) {
    const response = await http.post('/user_thing/untag', {
      id_thing,
      tag
    });
    return response.data;
  }

}

export default new UserDataService()
