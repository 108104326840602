<template>
  <suspense>
    <v-app>
      <Navigation></Navigation>
      <Debug v-if="isDevelopment"></Debug>
      <v-main>
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
        <GlobalDialog />
      </v-main>
    </v-app>
  </suspense>
</template>

<script>
import Navigation from './components/Navigation.vue'
import Debug from './components/Debug.vue'
import GlobalDialog from './components/custom/GlobalDialog'
import { mapState, mapActions } from 'pinia';
import { useConfigStore } from "./store/config";
import { useAuthStore } from "./store/auth";
import { useUserStore } from "./store/user";

export default {
  name: "app",
  components: {
    Navigation,
    Debug,
    GlobalDialog,
  },
  computed: {
    ...mapState(useConfigStore, ['isDevelopment']),
  },
  methods: {
    ...mapActions(useAuthStore, { initializeAuth: 'initialize' }),
    ...mapActions(useUserStore, { initializeUser: 'initialize' }),
  },
  async mounted() {
    await this.initializeAuth();
    await this.initializeUser();
  }
}
</script>
