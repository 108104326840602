import { defineStore, mapActions } from 'pinia'
import { reactive, toRefs } from 'vue'
import { uniq } from 'lodash'
import UserThingDataService from '../services/UserThingDataService';

export const useUserStore = defineStore('user', () => {

  const state = reactive({
    loading: true,
    userThings: [],
  });

  async function initialize() {
    state.userThings = await UserThingDataService.myThings();
    state.loading = false;
  }

  const isLiked = idThing => state.userThings.some(ut => ut.id_thing === idThing && ut.type === 'like');
  const isWished = idThing => state.userThings.some(ut => ut.id_thing === idThing && ut.type === 'wish');
  const isSell = idThing => state.userThings.some(ut => ut.id_thing === idThing && ut.type === 'sell');

  const getTagsForThing = idThing => state.userThings.filter(ut => ut.id_thing === idThing && ut.type === 'tag');
  // const getTagsForThing = idThing => state.userThings.filter(ut => ut.id_thing === idThing && ut.type === 'tag');

  // unique tags
  const getAllTags = () => uniq(state.userThings.filter(ut => ut.type === 'tag').map(ut => ut.tag));

  const saveTagsForThing = async (idThing, tags) => {
    // Get the existing tag objects and extract the tag values
    const existingTags = getTagsForThing(idThing);
    const existingTagValues = existingTags.map(t => t.tag);

    // Determine which tags are new and which are removed
    const newTags = tags.filter(t => !existingTagValues.includes(t));
    const removedTags = existingTagValues.filter(t => !tags.includes(t));

    await Promise.all([
      ...newTags.map(tag => UserThingDataService.tag(idThing, tag)),
      ...removedTags.map(tag => UserThingDataService.untag(idThing, tag)),
    ]);
    await initialize();
  }

  const toggleLike = async idThing => {
    await UserThingDataService.like(idThing);
    await initialize();
  }

  const toggleSell = async idThing => {
    await UserThingDataService.sell(idThing);
    await initialize();
  }

  const toggleWish = async idThing => {
    await UserThingDataService.wish(idThing);
    await initialize();
  }

  const getOwnedCount = (idThing) => state.userThings.filter(ut => ut.id_thing === idThing && ut.type === 'own').length;
  const isOwned = (idThing) => getOwnedCount(idThing) > 0;

  const own = async idThing => {
    await UserThingDataService.own(idThing);
    await initialize();
  }

  const unownAny = async (idThing) => {
    await UserThingDataService.unownAny(idThing);
    await initialize();
  }

  return {
    ...toRefs(state),
    initialize,
    isLiked,

    isWished,
    isSell,

    isOwned,
    getOwnedCount,
    own,
    unownAny,

    getTagsForThing,
    getAllTags,
    saveTagsForThing,
    toggleLike,
    toggleWish,
    toggleSell,
  }
})
