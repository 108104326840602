import { createApp } from 'vue'
import { createVuetify } from "vuetify"
import VueKonva from 'vue-konva'
import './styles/main.scss'
import CKEditor from '@ckeditor/ckeditor5-vue'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'

import { createPinia } from 'pinia'

import * as directives from 'vuetify/directives'
import * as vuetifyComponents from 'vuetify/components'
import { VTreeview } from 'vuetify/labs/VTreeview'
const components = {
  ...vuetifyComponents,
  VTreeview
}

import App from './App.vue'
import router from './router'

import { md } from "vuetify/iconsets/md";
import { aliases, mdi } from "vuetify/iconsets/mdi";


const vuetify = createVuetify({
  icons: {
    defaultSet:  'mdi',
    aliases,
    sets: {
      md,
      mdi
    }
  },
  components,
  directives,
  theme: {
    defaultTheme: 'dark',
  },
})

const app = createApp(App)

const pinia = createPinia()
app.use(pinia)

app.use(vuetify)
app.use(VueKonva, { prefix: 'Konva' })

app.use(router)
app.use(CKEditor)
app.mount('#app')
